
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}


.navbar-brand {
  img {
    width: 150px;
  }
}

.feature-icon {
  max-width: 135px;
  min-height: 135px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.af-form {
  --primary-color: #084169;
  --border-focus-color: #084169;
  --font-family: "Open Sans", sans-serif;
  --label-font-weight: 600;
  --input-background: #fff;
}

.af-form .af-legal a {
  text-decoration: underline;
}